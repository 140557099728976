import base64toblob from "base64toblob";
export default {
  data() {
    return {
      scale: 100,
      quality: 85,
    };
  },

  created() {},

  methods: {
    handleFile(file) {
      let vm = this;

      // console.log(Math.round(file.size / 1000)+' kB');
      // START: preview original
      // let reader = new FileReader();
      // reader.onload = (e) => this.originalImg = e.target.result;
      // reader.readAsDataURL(file);
      // END: preview original

      // START: preview resized
      this.resizeImage({ file: file, maxSize: 1600 })
        .then((res) => {
          // vm.image_data = res.blob;
          // vm.image_file = res.file;
          vm.done(res);
          // console.log(Math.round(res.file.size / 1000)+' kB');
        })
        .catch((err) => {
          console.error(err);
        });
      // END: preview resized
    },

    resizeImage({ file, maxSize }) {
      let reader = new FileReader();
      let image = new Image();
      let canvas = document.createElement("canvas");
      // let scale = this.scale / 100;
      let quality = this.quality ? this.quality / 100 : 1;
      let resize = () => {
        let { width, height } = image;
        // ==========================
        // cách 1:
        // width = width * scale;
        // height = height * scale

        // cách 2:
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
        // ==========================
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);

        let base64 = canvas.toDataURL("image/jpeg", quality);
        let fileName = file.name;
        let lastDot = fileName.lastIndexOf(".");
        fileName = fileName.substr(0, lastDot) + ".jpeg";
        // console.log(base64);
        let compressed = {
          blob: this.toBlob(base64),
          base64: base64,
          file: this.dataURLtoFile(base64, fileName),
        };
        return compressed;
      };

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }
        reader.onload = (readerEvent) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      });
    },

    // Convert Base64 to Blob
    toBlob(imgUrl) {
      let blob = base64toblob(imgUrl.split(",")[1], "image/jpeg");
      let url = window.URL.createObjectURL(blob);
      return url;
    },

    // Convert Base64 To File
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
};
