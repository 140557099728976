<template>
  <div>
    <v-file-input
      small-chips
      label="Chọn image"
      @change="onChange"
      accept="image/*"
    ></v-file-input>
  </div>
</template>

<script>
import image_compress from "@/mixins/image_compress.js";
export default {
  name: "ImageCompress",
  mixins: [image_compress],
  props: {
    // Pass the files info when it's done
    done: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {};
  },

  methods: {
    onChange(file) {
      if (file == undefined) {
        let empty = {
          blob: null,
          base64: null,
          file: null,
        };
        this.done(empty);
        return;
      }
      this.handleFile(file);
    },
  },
};
</script>
